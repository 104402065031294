import React from 'react';
import Modal from "../../../modal/modal";

const HireCmoDescription = () => (
    <>
        <p>When you hire a CMO, you are getting a strategist, planner, project manager, campaign manager, budget manager, production manager, brand manager, someone who can guide marketing tech/stack investment conversation/decisions and be a sounding board for someone who is as excited and into your brand/business as you are.</p>

        <Modal title='What you are buying' button='Read the fine print here'>
            <p>When you hire a CMO, you are getting a strategist, planner, project manager, campaign manager, budget manager, production manager, brand manager, someone who can guide marketing tech/stack investment conversation/decisions and be a sounding board for someone who is as excited and into your brand/business as you are.</p>
            <p>What we do, is scope out the projects and campaigns we want to launch and budgets we have to play with for design, media buy, website building, etc.. and hit the ground running.</p>
            <p>We can bolt on as your CMO temporarily, permanently or as needed for major projects/initiatives and launches.</p>
            <p>Creative services such as  graphic design, web development, copywriting, photography/videography, etc.. are all billed at $50 per hour to cover the cost of working with really talented and brilliant creatives who can deliver on the CMO’s vision.</p>
        </Modal>
    </>
)

export default HireCmoDescription;
