import React from 'react';
import Modal from "../../../../modal/modal";

const StudioHalfDayOpt1 = () => (
    <>
        <p>B.Y.O.E = Bring Your Own Equipment</p>
        <p>This means that you are 100% responsible for bringing everything that you need to run your show/presentation/interview, and we are just providing you with the (thoughtfully crafted) room to set up in.</p>
        <p><i>This booking will include 4 hours of recording time plus 1 hour of setup/tear down for a total booking time of 5 hours.</i></p>

        <Modal title='THE FINE PRINT ' button='Read the fine print here'>
            <p>Due to the nature of running live events, recorded events/shows/productions – we are not held responsible for power outages, internet issues, uncontrollable noises or technical glitches on your computer or with the software you use for your meetings/presentations/shows, nor if the building/space because unusable or inaccessible because of a safety, health, fire, hazard, etc., issue or concern.</p>
            <p>If you are interested in scriptwriting, design or editing services, please let us know on your intake form and we will send you a price list. If you are interested in renting some equipment for your booking, please let us know on the same form and we can send along a price list also.</p>
            <p>This ½ day studio rental includes 4 hours of filming/recording time plus 1 hour of setup/teardown time for a total booking time of 5 hours. The studio space will not be open prior to your booking time to ensure that no other studio sessions are disturbed during their bookings.</p>
            <p>If you go over your booking time you will be billed at a rate of $50 per 15 minutes, rounded up to the nearest 15 minutes (for example, if your booking ends at 3:00pm and you are still have people or equipment in the studio space or our office at 3:06pm, you will be billed for an extra $50. If you are still there at 3:22pm, you will be billed for an extra $100). This is to ensure that everyone is respectful of each other’s booking times so that when you show up for your booking someone else is not still in your space. We strongly suggest erring on the side of caution when booking your studio time to avoid extra fees.</p>
            <p>Any extra fees are payable within 30 days. If fees have not been paid within 30 days, a 5% late payment fee will be added and compounded for every 30 days that payment is late.</p>
            <p>You are pre-paying for your booking/reservation. We do not currently allow for cancellations as it means that we are unable to offer that time/space to someone else. If you do not show up to your booking you will not receive a refund on your booking. If you email us prior to your booking to cancel your reservation, we will note that you are not coming but you will not receive a refund on your booking.</p>
        </Modal>
    </>
);

export default StudioHalfDayOpt1;