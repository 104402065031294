import React from 'react';
import FormField from "../../../forms/FormField";
import useForm from "../../components/useForm"
import validate from "../../components/formValidationRules";

const StepProjectDetails = ({prevStep, nextStep, state, handleStepChanges}) => {

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        touched,
        isSubmitting
    } = useForm(state.project, validate);

    const Continue = (e) => {
        e.preventDefault();

        if (handleSubmit(e)) {
            nextStep();
        }
    };

    const Back = (e) => {
        e.preventDefault();
        prevStep();
    };

    const handleValidation = (e) => {
        handleStepChanges('project',
            {
                [e.target.name]: e.target.value
            });
        handleChange(e);
    };

    return (
        <div className="checkout-project-details">
            <h5 className="heading">Project Details</h5>

            <form className="project-details-body" noValidate>
                <FormField
                    name="company"
                    type="text"
                    value={state.project.company}
                    placeholder="Company Name"
                    required="required"
                    onChange={handleValidation}
                    onBlur={handleBlur}
                    errors={errors.company}
                    touched={touched}
                    isSubmitting={isSubmitting}
                />

                <FormField
                    name="email"
                    type="text"
                    value={state.project.email}
                    placeholder="Email"
                    required="required"
                    onChange={handleValidation}
                    onBlur={handleBlur}
                    errors={errors.email}
                    touched={touched}
                    isSubmitting={isSubmitting}
                />

                <FormField
                    name="name"
                    type="text"
                    value={state.project.name}
                    placeholder="Project Name"
                    required="required"
                    onChange={handleValidation}
                    onBlur={handleBlur}
                    errors={errors.name}
                    touched={touched}
                    isSubmitting={isSubmitting}
                />
            </form>

            <div className="checkout-nav">
                { prevStep && (
                    <button type="button" className="btn btn-prev" onClick={Back}><i
                        className="font-icon arrow-right"> </i> Back
                    </button>
                )}
                <button type="button" className="btn btn-next" onClick={Continue}>Continue</button>
            </div>
        </div>
    )
};

export default StepProjectDetails;
