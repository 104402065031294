import React from 'react';
import Modal from "../../../../modal/modal";

const StudioHourlyOpt3 = () => (
        <>
            <p>The whole enchilada! With the full support of our team, all the equipment you could possibly need, and a pre-production planning meeting, we’ve got you covered so that you can do what you do best – shine bright in your show/presentation/podcast/webinar/event!</p>

            <Modal title='THE FINE PRINT ' button='Read the fine print here'>
                <p>Due to the nature of running live events, recorded events/shows/productions – we are not held responsible for power outages, internet issues, uncontrollable noises or technical glitches on your computer or with the software you use for your meetings/presentations/shows, nor if the building/space because unusable or inaccessible because of a safety, health, fire, hazard, etc., issue or concern.</p>
                <p>If you are interested in scriptwriting, design or editing services, please let us know on your intake form and we will send you a price list.</p>
                <p>When you pay for our full production support, we take care of the technical setup, recording, tear down, and testing before, during, and after your production. This relieves a lot of stress and worry and allows you to focus on what you really want to do – create/deliver great content. We do, however, strongly recommend practice sessions and booking as much time as you can allow before you need to go live to get settled and plugged in. The more practice time you book/reserve – the better your set up will be.</p>
                <p>The support of our production team also means that there will be a pre-production call with you and your creative team to ensure that we can help you realize your vision smoothly and efficiently. This means we will need to review any scripts you have, special angles, tracking, complicated lighting, etc, and create a storyboard after the call to ensure everyone is on the same page and a solid plan is in place for your production. Involved in the call will be our Lab’s Creative Director, Shoot Stylist and Support and Head of Audio/Visual. This meeting will be 45 minutes so we highly recommend coming to this meeting fully prepared so that we can take complete advantage of our time together to focus on making your production excellent.</p>
                <p>You will be responsible for bringing yourself, your guests, any props or clothing you need, whatever you plan on storing your recording on (ie/ flash drive or hard drive) and, most importantly, your laptop, already installed with whatever webinar or broadcasting tool you are planning on using. We will do our absolute best to assist you if you’re having issues with your broadcasting tool, but we cannot guarantee that we can make your system on your computer work.</p>
                <p>When you book your studio time there is an additional 30 minutes prior to the booking time that is reserved for you and your guests to come into the studio space and get hooked up with microphones, check camera angles and lighting, and ensure everything is 100% good to go by the time your booking slot starts.</p>
                <p>If you and your guests do not arrive 30 minutes prior to your booking time, we will do our best to assist you, but there is absolutely no guarantee that you will have microphones clipped on and tested, lighting adjusted to skin tone and camera angles triple checked by the time your booking time starts. Additional time will not be added to your booking time if you are late to setup.</p>
                <p>If you go over your booking time you will be billed at a rate of $120 per 15 minutes, rounded up to the nearest 15 minutes (for example, if your booking ends at 3:00pm and you still have guests in the studio space at 3:06pm, you will be billed an extra $75. If you are still there at 3:22pm, you will be billed for an extra $240). This is to ensure that everyone is respectful of each other’s booking times so that when you show up for your booking someone else is not still in your space. Again, we strongly suggest erring on the side of caution when booking your studio time to avoid extra fees.</p>
                <p>Any extra fees are payable within 30 days. If fees have not been paid within 30 days, a 5% late payment fee will be added and compounded for every 30 days that payment is late.</p>
                <p>After the pre-production call with our production team, you will be sent an equipment list showing the equipment that has been selected to make your production incredible, as well as your booking time/date and any related production notes and will be asked to confirm the equipment needed and sign a waiver taking full responsibility for any theft or damage to the equipment that occurs during your booking that is caused by yourself or your guests.</p>
                <p>If we do not have the equipment you need for your booking, which is highly unlikely, and cannot find an alternative option, we will work with you on a case-by-case basis to come to a solution that works for everyone.</p>
                <p>You are pre-paying for your booking/reservation. We do not currently allow for cancellations as it means that we are unable to offer that time/space to someone else. If you do not show up to your booking you will not receive a refund on your booking. If you email us prior to your booking to cancel your reservation, we will note that you are not coming but you will not receive a refund on your booking.</p>
            </Modal>
        </>
);

export default StudioHourlyOpt3;