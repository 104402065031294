import React from "react";
import Modal from "../../../../modal/modal";

const ProductPhotosOpt2 = () => (

    <>
        <p>Essentially, the brainpower and creative talent of our photo team (our Creative Director, Head of Photography and Shoot Stylist) to make your product photos really stand out and help nail down your product photography style, identity, and aesthetic. The first part of this process will be an intake form where we will learn everything we can about your products and brand. Then we will digest all that information and give you customized recommendations on everything from lighting to styling to angles to 360° video in a one-on-one meeting with moodboards.</p>
        <p><i>This fee does not include the shooting or styling of your photos or videos and does not cover any additional work you may choose to move forward with from our design, styling or photo teams.</i></p>

        <Modal title='What you are buying' button='Read the fine print here'>
            <p>Our photo team will require 5 business days after we receive your completed intake form to create your moodboards before the consultation can occur, so please leave at least 5 business days between the completion of your form and the consultation time or we will move the consultation time.</p>
            <p>If we need to change the consultation time due to an emergency, natural disaster, illness, etc, our Creative Director will reach out to rebook. If you need to change the consultation time, please give us at least 72 hours notice. </p>
            <p>This consulting package does not include the cost of shooting your products or 360° videos, nor does it include any extra time from our design, styling, or photo team that may be recommended in the consultation.</p>
            <p>This consultation package covers product photography only, NOT lifestyle images that include models, props or travel. </p>
            <p>Upon delivery of the moodboards and recommendations document, you have the right to use those files however you like for as long as you like. </p>
        </Modal>
    </>

)
export default ProductPhotosOpt2;