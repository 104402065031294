import React from "react";
import Modal from "../../../../modal/modal";


const ProductPhotosOpt1 = () => (
    <>
        <p>1-5 (please select desired amount) high-res, optimized product photos, perfect for use on your website, ecommerce platforms, social media shops and catalogs or even print projects. Photos will all have a white background for maximum flexibility unless otherwise requested.</p>

        <Modal title='What you are buying' button='Read the fine print here'>
            <p>The normal turnaround time for photos is 5-7 business days after approval of the moodboard. Moodboards take 3-5 business days to create after we receive your products in our Vancouver location. </p>
            <p>Even though many of our projects carry the same tone/feel because they are being used on Amazon, Shopify (eww), WooCommerce, BigCommerce, Magento, etc… we still feel it is important and imperative that we all agree on the angles, the specific lighting and light direction, and the highlight images that might be cropped tighter or home in on a particular characteristic. This is what really elevates performance on your listings and product description pages. You will have the chance to outline all of this on the intake form we will send you upon purchasing.</p>
            <p>We don’t charge a rush fee as everything is done on a first come, first served basis (adjusted for delays in shipping/delivery). You can’t buy your way to the top of our list, but you can contact our parent agency if you have deep enough pockets to buy their attention.</p>
            <p>There are no additional fees as these are NOT lifestyle images that include models, props or travel.</p>
            <p>You are responsible for the cost and logistics of getting your products delivered to us safely in a timely manner and we will keep the products. This may benefit you down the road as we may include your product as props in our lifestyle shoots (with appropriate tagging and credit).</p>
            <p>If possible, please send multiples of your product so that we can try different things with lighting effects and product placement. This allows us to keep 1-2 products in perfect condition in case damage, scuffs or wear and tear appear while we are practicing with your product.</p>
            <p>Please make sure to wrap your product carefully, even if it is not fragile as the label/print or surface of the product packaging itself will show off all slight imperfections under our bright lights. The Hot Soup Lab is not responsible for any damage that occurs to your products during shipping or shooting.</p>
            <p>Upon delivery of the final production images/clips, you have the right to use those images however you like for as long as you like. Because you pre-paid there will be no issues or arguing – everything is on the up and up.</p>
        </Modal>
    </>
)

export default ProductPhotosOpt1;
