import React from "react"
import ProductPhotographyDescription from './product-photography';
import StudioRentalDescription from './studio-rental';
import WebsiteBuildDescription from './website-build';
import ContentPRStrategyDescription from './content-pr-strategy';
import HireCmoDescription from './hire-cmo';
import WebmasterITSupportDescription from './webmaster-it-support';
import DigitalAdvertisingDescription from './digital-advertising';

const ProductDesc = ({type, uid}) => {

    const description = () => {
        switch(type) {
            case 'product-photography': return <ProductPhotographyDescription uid={uid} />;
            case 'studio-rental': return <StudioRentalDescription uid={uid} />;
            case 'website-build': return <WebsiteBuildDescription />;
            case 'content-pr-strategy': return <ContentPRStrategyDescription />;
            case 'hire-cmo': return <HireCmoDescription />;
            case 'web-it-support': return <WebmasterITSupportDescription />;
            case 'digital-advertising': return <DigitalAdvertisingDescription />;
            default:  return <h1>No project match</h1>
        }
    };

    return (
        <>
            <h5 className="heading">What you are buying</h5>
            <div>{ description() }</div>
        </>
    )
};
export default ProductDesc;
