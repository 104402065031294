import React from "react";
import StudioHourlyOpt1 from "./studio-rental/StudioHourlyOpt1";
import StudioHourlyOpt2 from "./studio-rental/StudioHourlyOpt2";
import StudioHourlyOpt3 from "./studio-rental/StudioHourlyOpt3";
import StudioHalfDayOpt1 from "./studio-rental/StudioHalfDayOpt1";
import StudioHalfDayOpt2 from "./studio-rental/StudioHalfDayOpt2";
import StudioHalfDayOpt3 from "./studio-rental/StudioHalfDayOpt3";
import StudioFullDayOpt1 from "./studio-rental/StudioFullDayOpt1";
import StudioFullDayOpt2 from "./studio-rental/StudioFullDayOpt2";
import StudioFullDayOpt3 from "./studio-rental/StudioFullDayOpt3";

const StudioRentalDescription = ({uid}) => {

    switch(uid) {
        case 'StudioHourlyOpt1': return <StudioHourlyOpt1 />;
        case 'StudioHourlyOpt2': return <StudioHourlyOpt2 />;
        case 'StudioHourlyOpt3': return <StudioHourlyOpt3 />;
        case 'StudioHalfDayOpt1': return <StudioHalfDayOpt1 />;
        case 'StudioHalfDayOpt2': return <StudioHalfDayOpt2 />;
        case 'StudioHalfDayOpt3': return <StudioHalfDayOpt3 />;
        case 'StudioFullDayOpt1': return <StudioFullDayOpt1 />;
        case 'StudioFullDayOpt2': return <StudioFullDayOpt2 />;
        case 'StudioFullDayOpt3': return <StudioFullDayOpt3 />;
        default:  return <h1>No project match</h1>
    }

}

export default StudioRentalDescription;
