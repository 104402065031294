import React from 'react';
import Modal from "../../../modal/modal";

const ContentPRStrategyDescription = () => (
    <>
        <p>When you work with us to develop a content and PR strategy, you are getting everything you need to plan, create and deliver incredible brand content to delight, entertain, engage, educate and inform your people.</p>

        <Modal title='What you are buying' button='Read the fine print here'>
            <p>When you work with us to develop a content and PR strategy, you are getting everything you need to plan, create and deliver incredible brand content to delight, entertain, engage, educate and inform your people.</p>
            <p>We create the templates you need for the given month/quarter as well as develop the story lines and content arc for you to follow, along with reporting and micro-adjustments along the way + recommendations on promoting (converting your organic content into paid advertising) your content.</p>
            <p>It is important to have you and your team be the hands, faces and voice of your brand and be plugged into and active in your community, which is part of how we help support your brand and business. We allow you to plan for these new/additional marketing and communication roles that you will want to have within your business.</p>
            <p>We also provide pitch stories and recommendations on outlets and people to approach with your story to get organic coverage and engagement with media leaders.</p>
        </Modal>
    </>
)

export default ContentPRStrategyDescription;
