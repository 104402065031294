import React from "react"
import Layout from "~components/base/layout"
import SEO from "~components/base/seo"
import CheckoutHeading from "~components/checkout/CheckoutHeading";
import CheckoutSteps from "~components/checkout/CheckoutSteps";
import "~components/checkout/_checkout-steps.scss"
import ProductQuery from "~components/checkout/components/ProductQuery";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

const CheckoutPage = ({ props, location}) => {

    const {state = {}} = location;
    const data = state ? ProductQuery(state.id) : null;

    return (
        <Elements stripe={stripePromise} {...props}>
            <Layout type="template-default page-checkout">
                <SEO title="Checkout"/>
                <section className="page-heading">
                    <div className="container">
                        <h1 className="title">Checkout</h1>
                    </div>
                </section>
                <div className="container">
                    <section className="section-checkout-form">
                        {data ? <>
                                <CheckoutSteps
                                    product={data.node}
                                    onSuccessfulCheckout={() => window.location = '/thank-you'}
                                />
                            </>
                            : <>
                                <CheckoutHeading title="No items in cart" subtitle=""/>
                                <p>Please select product first!</p>
                            </>
                        }
                    </section>
                </div>
            </Layout>
        </Elements>
    )

};

export default CheckoutPage
