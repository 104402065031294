import React from "react";
import Modal from "../../../modal/modal";

const WebsiteBuildDescription = () => (
    <>
        <p>Planning, designing and building a website is rarely, if ever, a simple process. However, we want to make this as straightforward as we can, which means limiting flexibility. Flexibility comes with bigger budgets. What you get hear is speed and quality.</p>

        <Modal title='What you are buying' button='Read the fine print here'>
            <p>Planning, designing and building a website is rarely, if ever, a simple process. However, we want to make this as straightforward as we can, which means limiting flexibility. Flexibility comes with bigger budgets. What you get hear is speed and quality.</p>
            <p>We outline and develop plans for up to 5 categories of page (e.g. home, about, product, blog and landing page) templates. From there, we will build up to 10 unique pages, providing you with everything you need to add more pages, update existing pages or modify on of our page templates.</p>
            <p>Depending on the package you choose, we will also help with planning and creating the content and designing it into the website build for you.</p>
            <p>We highly encourage and recommend our Webmaster and mini-IT Department support packages to be there for you in an emergency, check for bugs, update software, act as 24/7 support + help with minor updates and changes.</p>
        </Modal>
    </>
);

export default WebsiteBuildDescription;
