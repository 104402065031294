import React from 'react';
import Modal from "../../../../modal/modal";

const StudioFullDayOpt2 = () => (
    <>
        <p>Check 1, 2, 3! Our equipment package includes everything you need to run your show/presentation/production in our studio including lights, cameras and audio equipment.</p>
        <p><i>This booking will include 8 hours of recording time plus 1 hour of pre-booking time to ensure microphones are connected and attached to you/your guests, cameras and lighting are adapted for your guests, etc, for a total booking time of 9 hours.</i></p>

        <Modal title='THE FINE PRINT ' button='Read the fine print here'>
            <p>Due to the nature of running live events, recorded events/shows/productions – we are not held responsible for power outages, internet issues, uncontrollable noises or technical glitches on your computer or with the software you use for your meetings/presentations/shows, nor if the building/space because unusable or inaccessible because of a safety, health, fire, hazard, etc., issue or concern.</p>
            <p>If you are interested in scriptwriting, design or editing services, please let us know on your intake form and we will send you a price list.</p>
            <p>When you pay to use our equipment, we take care of the technical setup, tear down, and testing before and after your production. This relieves a lot of stress and worry and allows you to focus on what you really want to do – create/deliver great content. We do, however, strongly recommend practice sessions and booking as much time as you can allow before you need to go live to get settled and plugged in. The more practice time you book/reserve – the better your set up will be.</p>
            <p>You will be responsible for bringing yourself, your guests, any props or clothing you need, whatever you plan on storing your recording on (ie/ flash drive or hard drive) and, most importantly, your laptop, already installed with whatever webinar or broadcasting tool you are planning on using. We will do our best to assist you if you’re having issues with your broadcasting tool but we cannot guarantee that we can make your system on your computer work and this package does not include a support team for the duration of your booking to hit start/stop/etc.</p>
            <p>When you book your studio time there is an additional 60 minutes prior to the booking time that is reserved for you and your guests to come into the studio space and get hooked up with microphones, check camera angles and lighting, and ensure everything is 100% good to go by the time your booking slot starts.</p>
            <p>If you and your guests do not arrive 60 minutes prior to your booking time, we will do our best to assist you, but there is absolutely no guarantee that you will have microphones clipped on and tested, lighting adjusted to skin tone and camera angles triple checked by the time your booking time starts. Additional time will not be added to your booking time if you are late to setup.</p>
            <p>If you go over your booking time you will be billed at a rate of $75 per 15 minutes, rounded up to the nearest 15 minutes (for example, if your booking ends at 6:00pm and you still have guests in the studio space at 6:06pm, you will be billed for an extra $75. If you are still there at 6:22pm, you will be billed an extra $150). This is to ensure that everyone is respectful of each other’s time. We strongly suggest erring on the side of caution when booking your studio time to avoid extra fees.</p>
            <p>Any extra fees are payable within 30 days. If fees have not been paid within 30 days, a 5% late payment fee will be added and compounded for every 30 days that payment is late.</p>
            <p>Prior to your studio booking you will have a short call with our Head of Audio/Visual to confirm the equipment you will need. You will then be sent an equipment list showing the equipment you requested, and your booking time/date, and will be asked to confirm the equipment needed and sign a waiver taking full responsibility for any theft or damage to the equipment that occurs during your booking that is caused by yourself or your guests.</p>
            <p>If we do not have the equipment you need for your booking, which is highly unlikely, and cannot find an alternative option, we will work with you on a case-by-case basis to come to a solution that works for everyone.</p>
            <p>You are pre-paying for your booking/reservation. We do not currently allow for cancellations as it means that we are unable to offer that time/space to someone else. If you do not show up to your booking you will not receive a refund on your booking. If you email us prior to your booking to cancel your reservation, we will note that you are not coming but you will not receive a refund on your booking.</p>
        </Modal>
    </>
);

export default StudioFullDayOpt2;