import React from "react";
import DecrementButton from "../buttons/decrementButton"
import IncrementButton from "../buttons/incrementButton"

import "./_qunatity.scss"

const Quantity = ({state, stepCalcText, handleStepChanges, error}) => {

    const handleDecrement = () => {
        const {value, min} = state.quantity;
        const {amount} = state;
        const {price} = state.product;

        if (value > min) {
            handleStepChanges('quantity', {
                value: value - 1
            });
            handleStepChanges('amount', amount > 0 ? amount - price : price);
        }
    };

    const handleIncrement = () => {
        const {max} = state.quantity;

        let quantity = state.quantity.value;
        let {price} = state.product;

        if (quantity < max) {
            handleStepChanges('quantity', {
                value: ++quantity
            });
            handleStepChanges('amount', price * quantity);
        }

    };

    const handleQuantityChange = (e) => {

        const value = e.target.value.replace(/\D/, '');

        handleStepChanges('quantity', {
            value: value
        });
        handleStepChanges('amount', value * state.product.price)
    };

    const renderPrompt = (param) => {
        switch (param) {
            case 'photo-1':
                return <>How many photos?</>;
            case 'photo-2':
                return <>How many unique<br/> products or SKUs?</>;
            case 'photo-3':
                return 'Minimum order of 5 photos';
            default:
                return <>Enter number of <br/> hours 1-10</>
        }
    };

    return (
        <div className="checkout-quantity-column">
            { stepCalcText ? (
                <h4 className="heading">{stepCalcText}</h4>
            ) : (
                (state.product.type === "product-photography")
                    ? <h4 className="heading">Enter Numbers of Photos</h4>
                    : <h4 className="heading">Number of hours needed</h4>
            )}
            <div className="checkout-quantity-component">
                <div className="checkout-quantity">
                    <div className="items-count-group">
                        <DecrementButton onClick={handleDecrement}/>
                        <input type="text"
                               name="quantity"
                               maxLength="2"
                               onChange={handleQuantityChange}
                               value={state.quantity.value}
                        />
                        <IncrementButton onClick={handleIncrement}/>
                        {error.quantity && (
                            <small className="help-block with-errors">{error.quantity}</small>
                        )}
                    </div>

                    <p>{renderPrompt(state.product.id)}</p>

                </div>
                <div className="checkout-amount">
                    <input type="text" id="amount" value={`$${state.amount.toLocaleString()}`} readOnly={true}/>
                    <p>Total (+tax)</p>
                </div>
            </div>
        </div>
    );

};

export default Quantity;
