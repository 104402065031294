import React, {Component} from 'react';

import StepFormDetails from './steps/details/stepFormDetails';
import StepFormDetailsCombined from './steps/details_combined/details_combined';
import StepSubscriptionPlan from './steps/subscriptionPlan/subscriptionPlan';
import StepEventForm from './steps/eventForm/EventForm';
import StepProjectDetails from './steps/projectDetails/stepProjectDetails';
import StepFormPayment from "./steps/paymentForm/PaymentForm";

import "./_checkout-steps.scss";
import CheckoutHeading from "./CheckoutHeading";

export class checkoutSteps extends Component {

    constructor(props) {
        super(props);

        const amount = props.product.quantity.min > 1
            ? props.product.quantity.min * props.product.price
            : props.product.price;

        this.state = {
            id: this.generateID(),
            step: 0,
            steps: this.initSteps(props.product.type),
            hours: props.product.hours ? props.product.hours : 0,
            quantity: {
                value: props.product.quantity.min,
                min: props.product.quantity.min,
                max: props.product.quantity.max
            },
            amount: amount,
            totalAmount: amount + (amount * 0.05), // + GST Tax
            adsBudget: props.product.minBudget ? props.product.minBudget : 0,
            product: {
                type: props.product.type,
                ...props.product
            },
            checkout: {
                title: props.product.checkout.title,
                subtitle: props.product.checkout.subtitle,
            },
            stripe: null,
            calendar: {
              selectedDay: null,
              selectedTime: null
            },
            project: {
                name: '',
                company: '',
                email: ''
            },
            billing_details: {
                agreement: false,
                name: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                country: 'ca',
                zip: '',
            }
        }
    }

    generateID = () => {
       return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
    };

    initSteps = (type) => {
        switch (type) {
            case 'studio-rental':
                return ['details', 'calender', 'project', 'payment'];
            case 'digital-advertising':
                return ['subscription-plan', 'project', 'payment'];
            case 'website-build':
            case 'content-pr-strategy':
            case 'hire-cmo':
            case 'web-it-support':
                return ['details-combined', 'payment'];
            default:
                return ['details', 'project', 'payment']
        }
    };

    nextStep = () => {
        const {step} = this.state;
        this.setState({
            step: step + 1
        });
    };

    prevStep = () => {
        const {step} = this.state;
        this.setState({
            step: step - 1
        });
    };

    // Handle fields change
    handleChange = input => e => {
        this.setState({[input]: e.target.value});
    };

    handleStepChanges = (input, value) => {

        if (typeof value === 'object') {
            this.setState({
                [input]: {
                    ...this.state[input],
                    ...value
                }
            });
        } else {
            this.setState({
                [input]: value
            });
        }
    };

    render() {

        const {steps, step} = this.state;

        switch (steps[step]) {
            case 'details':
                return (
                    <>
                        <CheckoutHeading title={this.state.checkout.title} subtitle={this.state.checkout.subtitle}/>
                        <StepFormDetails
                            nextStep={this.nextStep}
                            handleStepChanges={this.handleStepChanges}
                            state={this.state}
                        />
                    </>
                );
            case 'details-combined':
                return (
                    <>
                        <CheckoutHeading title={this.state.checkout.title} subtitle={this.state.checkout.subtitle}/>
                        <StepFormDetailsCombined
                            nextStep={this.nextStep}
                            prevStep={false}
                            handleStepChanges={this.handleStepChanges}
                            state={this.state}
                        />
                    </>
                );
            case 'subscription-plan':
                return (
                    <>
                        <CheckoutHeading title={this.state.checkout.title} subtitle={'$' + this.state.amount.toLocaleString()}/>
                        <StepSubscriptionPlan
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleStepChanges={this.handleStepChanges}
                            state={this.state}
                        />
                    </>
                );
            case 'calender':
                return (
                    <>
                        <CheckoutHeading title={this.state.checkout.title} subtitle={this.state.checkout.subtitle}/>
                        <StepEventForm
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleStepChanges={this.handleStepChanges}
                            state={this.state}
                        />
                    </>
                );
            case 'project':
                return (
                    <>
                        <CheckoutHeading title={this.state.checkout.title} subtitle={this.state.checkout.subtitle}/>
                        <StepProjectDetails
                            handleStepChanges={this.handleStepChanges}
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}
                            state={this.state}
                        />
                    </>
                );
            case 'payment':
                return (
                    <>
                        <CheckoutHeading title={this.state.checkout.title} subtitle={this.state.checkout.subtitle}/>
                        <StepFormPayment
                            prevStep={this.prevStep}
                            handleStepChanges={this.handleStepChanges}
                            state={this.state}
                        />
                    </>
                );
            default:
                return (
                    <>Checkout Form not available</>
                )
        }
    }
}

export default checkoutSteps;
