import React from 'react'

const DecrementButton = ({ label, name, onClick }) => {

    return (
            <button type="button" onClick={onClick}>-</button>
    );
};

export default DecrementButton;
