import React from "react";
import Modal from "../../../modal/modal";

const WebmasterITSupportDescription = () => (
    <>
        <p>While 98% of the problems, issues and things you need help with are really simple and within our control to fix/update or correct – the other 2% are things we help you manage and deal with, but may not be fast to fix. </p>

        <Modal title='What you are buying' button='Read the fine print here'>
            <p>While 98% of the problems, issues and things you need help with are really simple and within our control to fix/update or correct – the other 2% are things we help you manage and deal with, but may not be fast to fix. </p>
            <p>That is why part of having a longer term agreement is smart because we can build proactive systems and processes + put in place security and redundancy tools that help mitigate that painful 2%. </p>
            <p>We are 100% NOT liable for things not working the way they should, because there is a really good chance we did not build the thing that is broken – but we are really here to make the Marketing IT and Webmaster management process painless and make sure you are not the one sitting on hold with GoDaddy for 4 hours in the middle of the night to find out why your website is unresponsive.</p>
            <p>We will always try and help and point you to the answer, even if it is unrelated to what we are helping you with.</p>
        </Modal>
    </>
);

export default WebmasterITSupportDescription;
