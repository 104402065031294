import React from "react";

const CheckoutHeading = ({title, subtitle}) => {

    return (
        <div className="checkout-product-heading">
            <h2>{title} | {subtitle}</h2>
        </div>
    );

};

export default CheckoutHeading;
