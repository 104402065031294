import React, {useEffect, useState} from 'react';
import Quantity from "../../components/quantity/quantity";
import Description from "../../components/description/description";

import "./_details.scss";
import Checkbox from "../../../forms/Checkbox";

const StepFormDetails = ({state, nextStep, handleStepChanges}) => {

    const [error, setError] = useState({});
    const [checked, setChecked] = useState(false);
    const [agreement, setAgreement] = useState(false);
    const {category, type, uid, checkout: {stepCalcText, stepAgreementText}} = state.product;

    useEffect(() => {

        switch (category) {
            case 'Product Photography':
            case 'Studio Rental':
                setAgreement(true);
                break;
            default:
                setChecked(true);
        }
    }, [])

    const Continue = (e) => {
        e.preventDefault();

        state.quantity.value > 0 && checked ? nextStep() : setError({quantity: 'Value must be greater than 0'});
    };
    const isActiveQuantity = state.product.quantity.enabled;

    return (
        <>
            <div className="checkout-form-details">
                {isActiveQuantity && (
                    <Quantity
                        stepCalcText={stepCalcText}
                        state={state}
                        handleStepChanges={handleStepChanges}
                        error={error}
                    />
                ) }
                <div className="checkout-desc">
                    <Description type={type} uid={uid ? uid : null}/>
                    {agreement && (
                        <Checkbox name="agree"
                                  className={'checkout-desc-agree'}
                                  defaultChecked={false}
                                  label={stepAgreementText}
                                  required
                                  onChange={() => setChecked(!checked)}
                        />
                    )}
                </div>
            </div>
            <div className="checkout-nav">
                <button type="button" className="btn btn-next" disabled={agreement && !checked} onClick={Continue}>Continue</button>
            </div>
        </>
    )
};

export default StepFormDetails;
