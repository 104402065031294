import React from "react";
import Modal from "../../../modal/modal";

const DigitalAdvertisingDescription = () => (
    <>
        <p>Digital advertising is not that complex, but it involves a lot of moving pieces and nuanced detail that make the difference from you setting up your own random ad or campaign – and our team setting up an integrated campaign that is intentionally designed to deliver results. </p>
           <Modal title='What you are buying' button='Read the fine print here'>
                    <p>Digital advertising is not that complex, but it involves a lot of moving pieces and nuanced detail that make the difference from you setting up your own random ad or campaign – and our team setting up an integrated campaign that is intentionally designed to deliver results. </p>
                    <p>With that being said, do not hire us for this type of campaign if you are in a dire financial situation – or are relying on our campaign results to keep your business afloat. The goal is to take your business to the next level, expand your brand’s reach and scale growth. We are here to save you from being taken advantage of from other “online marketing ninjas/gurus/experts” but it is rarely sustainable when you are hoping for us to save your business and finances in 90 days or less.</p>
                    <p>Results are 100% NOT guaranteed.</p>
                    <p>And you may not like what we learn from our campaigns and work. You may have a brand messaging problem a website/tech problem and/or a price and market fit problem that no amount of money or creative genius and masterful execution can correct for.</p>
                    <p>If we ask for more money to spend on your business (e.g. buy more ads, try new channels, etc..) – that is a good thing. Don’t make it harder for us to grow and build campaign success – we are in this together to help you grow, so we don’t care where the money is spent, we only care that it comes back and comes back worth more than when it left our hands.</p>
            </Modal>
    </>
);

export default DigitalAdvertisingDescription;
