import {graphql, useStaticQuery} from "gatsby";

const ProductQuery = (id) => {

    const data = useStaticQuery(graphql`
          query {
                allProductsJson {
                edges {
                  node {
                    id
                    uid
                    type
                    category
                    hours
                    quantity{
                        enabled
                        min
                        max
                    }
                    title
                    subtitle
                    price
                    minBudget
                    description
                    stripe{
                      priceID
                      productID
                      description
                    }
                    hubspot{
                        formId
                        lineItemId
                    }
                    checkout{
                        title
                        subtitle
                        stepCalcText
                        stepAgreementText
                    }
                  }
                }
             }
          }
      `)

    return data.allProductsJson.edges.find(n => {
        return n.node.id.includes(id);
    });

}

export default ProductQuery;
