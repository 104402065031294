const api = process.env.GATSBY_API_ASANA;

export const AsanaSyncData = (state) => {

    let due_date = new Date();
    due_date.setDate(due_date.getDate() + 5);
    due_date.toISOString().slice(0,10);

    return fetch(`${api}/createProject`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            project: {
                name: `${state.project.company} - ${state.project.name}`,
                notes: `Company: ${state.project.company}, Email: ${state.project.email} `,
                archived: false,
                public: false,
                workspace: "895057025841636",
                team: "1192867319829666",
                color: "light-green",
                followers: "953500923306495,1186901621688224,902155684504611,1162790432463667",
                start_on: new Date().toISOString().slice(0,10),
                due_date: due_date.toISOString().slice(0,10)
            },
            task: {
                approval_status: "pending",
                name: state.product.title,
                //assignee: "",
                completed: false,
                notes: `${state.product.subtitle}, Quantity: ${state.quantity.value}`,
                workspace: "895057025841636",
                followers: "953500923306495",
                projects: null,
                resource_subtype: "default_task",
            },
            user: {
                user: state.project.email
            }
        })
    })
    .then(response => response.json())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

};
