import React, {useState} from 'react';
import AdvertisingBudget from "./advertisingBudget";
import Description from "../../components/description/description";

const StepSubscriptionPlan = ({state, nextStep, handleStepChanges}) => {

    const [error] = useState({});

    const Continue = (e) => {
        e.preventDefault();

        nextStep();
    };

    return (
        <>
            <div className="checkout-form-details">
                <AdvertisingBudget
                    state={state}
                    handleStepChanges={handleStepChanges}
                    error={error}
                />
                <Description type={state.product.type} />
            </div>
            <div className="checkout-nav">
                <button type="button" className="btn btn-next" onClick={Continue}>Continue</button>
            </div>
        </>
    )
};

export default StepSubscriptionPlan;
